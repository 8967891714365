import React from 'react'

const Bag = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.8" transform="translate(.162 1.912)">
            <path d="M18.0675676,8.29087701 L2,8.29087701 C1.69624339,8.29087701 1.42124339,8.4139987 1.22218254,8.61305955 C1.02312169,8.8121204 0.9,9.0871204 0.9,9.39087701 L0.9,20.2361309 C0.9,20.8160299 1.13505051,21.3410299 1.51507576,21.7210551 C1.89510101,22.1010804 2.42010101,22.3361309 3,22.3361309 L17.0675676,22.3361309 C17.6474666,22.3361309 18.1724666,22.1010804 18.5524918,21.7210551 C18.9325171,21.3410299 19.1675676,20.8160299 19.1675676,20.2361309 L19.1675676,9.39087701 C19.1675676,9.0871204 19.0444459,8.8121204 18.845385,8.61305955 C18.6463242,8.4139987 18.3713242,8.29087701 18.0675676,8.29087701 Z"/>
            <path strokeLinecap="round" d="M14.8325499,5.13875971 C14.8325499,2.32537986 12.6840692,0.0446848671 10.0337838,0.0446848671 C7.38349842,0.0446848671 5.23501763,2.32537986 5.23501763,5.13875971"/>
        </g>
    </svg>

)

export default Bag