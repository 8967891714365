/**
 * Gorgias API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { hubspotApi } from '../helpers/gorgias'

    hubspotApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

    async function hubspotApi(endpoint, method, body, isSubscription) {
        let options = {
          method: method ? method : 'POST',

        };
      
        if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
        }
        if (isSubscription) {
          options['headers'] = {
            'X-Subscription': true
          };
        }
        const parseJson = async response => {
          const text = await response.text();
          try {
            const json = JSON.parse(text);
            return json;
          } catch (err) {
            return text;
          }
        };
      
        return await fetch(
          `/.netlify/functions/${endpoint}`,
          options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
      }
      
      export { hubspotApi };
      