import React from 'react'

// Alphabetical order please

import Add from './add'
import AddReview from './addReview'
import AfterPayArrows from './afterpay-arrows'
import AfterPayLogo from './afterpay-logo'
import AfterPay from './afterpay'
import Album from './album'
import Alert from './alert'
import Amex from './amex'
import ArrowDown from './arrowDown'
import ArrowLeft from './arrowLeft'
import ArrowRight from './arrowRight'
import ArrowRightRound from './arrowRight-round'
import ArrowRightPurple from './arrowRight-purple'
import ArrowRightLine from './arrowRight-line'
import ArrowRightLong from './arrowRight-long'
import ArrowWithTail from './arrowWithTail';
import Bag from './bag'
import Bin from './bin'
import CaretDown from './caretDown'
import Cart from './cart'
import CartAdd from './cartAdd'
import Check from './check'
import CheckFilled from './checkFilled'
import CheckOutline from './checkOutline';
import Click from './click'
import Close from './close'
import Currency from './currency'
import Edit from './edit'
import Equaliser from './equaliser'
import Facebook from './facebook'
import Faqs from './faqs'
import FileDoc from './fileDoc'
import FileImg from './fileImg'
import FileMp3 from './fileMp3'
import FileMp4 from './fileMp4'
import FileOther from './fileOther'
import FilePdf from './filePdf'
import FilePpt from './filePpt'
import FileXls from './fileXls'
import FileZip from './fileZip'
import Filter from './filter'
import GiftBox from './gift-box'
import Hamburger from './hamburger'
import Heart from './heart'
import Headset from './headset'
import HelpCircle from './help-circle'
import House from './house'
import Instagram from './instagram'
import Klarna from './klarna'
import KlarnaMin from './klarna-min';
import Link from './link'
import LinkedIn from './linkedin'
import Paypal from './paypal'
import PaypalMin from './paypal-min';
import PaypalColor from './paypal-color';
import MapMarker from './map-marker'
import MasterCard from './master-card';
import Minus from './minus';
import PaymentAdd from './paymentAdd'
import PaymentCheckout from './paymentCheckout'
import PaymentRepay from './paymentRepay'
import Phone from './phone'
import Pinterest from './pinterest'
import Play from './play'
import Purse from './purse'
import QuickView from './quickView'
import Search from './search'
import Star from './star'
import StarBlack from './star-black'
import StoreLocator from './store-locator'
import Swatch from './swatch'
import Tick from './tick'
import TikTok from './tikTok'
import Translate from './translate'
import Truck from './truck'
import TruckMoving from './truckMoving'
import Twitter from './twitter'
import User from './user'
import UserBlack from './user-black'
import View from './view'
import Visa from './visa';
import WhiteBag from './whiteBag'
import Youtube from './youtube'
import ZipLogo from './zip-logo'
import ZipMin from './zip-min';
import ZipMoneyLogo from './zip-money-logo';
import ZipPayLogo from './zip-pay-logo';
import IconArrow from './icon-arrow';
import IconDoubleQuptes from './icon-double-quotes';
import ArrowDownLine from './arrowDown-line'

function Icon({ symbol, fill }) {
  let SVGIcon;
  switch (symbol) {
    case 'add':
        SVGIcon = Add; break;
    case 'addReview':
      SVGIcon = AddReview; break;
    case 'afterpayArrows':
      SVGIcon = AfterPayArrows; break;
    case 'afterPayLogo':
      SVGIcon = AfterPayLogo; break;
    case 'afterPay':
      SVGIcon = AfterPay; break;
    case 'album':
      SVGIcon = Album; break;
    case 'alert':
      SVGIcon = Alert; break;
    case 'amex':
      SVGIcon = Amex; break;
    case 'arrowDown':
      SVGIcon = ArrowDown; break;
    case 'arrowDownLine':
      SVGIcon = ArrowDownLine; break;
    case 'arrowLeft':
      SVGIcon = ArrowLeft; break;
    case 'arrowRight':
      SVGIcon = ArrowRight; break;
    case 'arrowRightRound':
      SVGIcon = ArrowRightRound; break;
    case 'arrowRightPurple':
      SVGIcon = ArrowRightPurple; break;
    case 'arrowRightLine':
      SVGIcon = ArrowRightLine; break;
    case 'arrowRightLong':
      SVGIcon = ArrowRightLong; break;
    case 'arrowWithTail':
      SVGIcon = ArrowWithTail; break;
    case 'bag':
      SVGIcon = Bag; break;
    case 'bin':
      SVGIcon = Bin; break;
    case 'caretDown':
      SVGIcon = CaretDown; break;
    case 'cart':
      SVGIcon = Cart; break;
    case 'cartAdd':
      SVGIcon = CartAdd; break;
    case 'check':
      SVGIcon = Check; break;
    case 'checkFilled':
      SVGIcon = CheckFilled; break;
    case 'checkOutline':
        SVGIcon = CheckOutline; break;
    case 'click':
        SVGIcon = Click; break;
    case 'close':
      SVGIcon = Close; break;
    case 'currency':
      SVGIcon = Currency; break;
    case 'edit':
      SVGIcon = Edit; break;
    case 'equaliser':
      SVGIcon = Equaliser; break;
    case 'facebook':
      SVGIcon = Facebook; break;
    case 'faqs':
      SVGIcon = Faqs; break;
    case 'fileDoc':
      SVGIcon = FileDoc; break;
    case 'fileImg':
      SVGIcon = FileImg; break;
    case 'fileMp3':
      SVGIcon = FileMp3; break;
    case 'fileMp4':
      SVGIcon = FileMp4; break;
    case 'fileOther':
      SVGIcon = FileOther; break;
    case 'filePdf':
      SVGIcon = FilePdf; break;
    case 'filePpt':
      SVGIcon = FilePpt; break;
    case 'fileXls':
      SVGIcon = FileXls; break;
    case 'fileZip':
      SVGIcon = FileZip; break;
    case 'filter':
      SVGIcon = Filter; break;
    case 'giftbox':
      SVGIcon = GiftBox; break;
    case 'hamburger':
      SVGIcon = Hamburger; break;
    case 'heart':
      SVGIcon = Heart; break;
    case 'headset':
      SVGIcon = Headset; break;
    case 'helpCircle':
      SVGIcon = HelpCircle; break;
    case 'house':
      SVGIcon = House; break;
    case 'instagram':
      SVGIcon = Instagram; break;
    case 'klarna':
      SVGIcon = Klarna; break;
    case 'klarna-min':
      SVGIcon = KlarnaMin; break;
    case 'link':
      SVGIcon = Link; break;
    case 'linkedIn':
      SVGIcon = LinkedIn; break;
    case 'paypal':
      SVGIcon = Paypal; break;
    case 'paypal-min':
      SVGIcon = PaypalMin; break;
    case 'paypal-color':
      SVGIcon = PaypalColor; break;
    case 'mapMarker':
      SVGIcon = MapMarker; break;
    case 'masterCard':
      SVGIcon = MasterCard; break;
    case 'minus':
      SVGIcon = Minus; break;
    case 'paymentAdd':
      SVGIcon = PaymentAdd; break;
    case 'paymentCheckout':
      SVGIcon = PaymentCheckout; break;
    case 'paymentRepay':
      SVGIcon = PaymentRepay; break;
    case 'phone':
      SVGIcon = Phone; break;
    case 'pinterest':
      SVGIcon = Pinterest; break;
    case 'play':
      SVGIcon = Play; break;
    case 'purse':
      SVGIcon = Purse; break;
    case 'quickView':
      SVGIcon = QuickView; break;
    case 'search':
      SVGIcon = Search; break;
    case 'star':
      SVGIcon = Star; break;
    case 'starBlack':
      SVGIcon = StarBlack; break;
    case 'storeLocator':
      SVGIcon = StoreLocator; break;
    case 'swatch':
      SVGIcon = Swatch; break;
    case 'tick':
      SVGIcon = Tick; break;
    case 'tikTok':
      SVGIcon = TikTok; break;
    case 'translate':
      SVGIcon = Translate; break;
    case 'truck':
      SVGIcon = Truck; break;
    case 'truckMoving':
      SVGIcon = TruckMoving; break;
    case 'twitter':
      SVGIcon = Twitter; break;
    case 'user':
      SVGIcon = User; break;
    case 'userblack':
      SVGIcon = UserBlack; break;
    case 'view':
      SVGIcon = View; break;
    case 'visa':
      SVGIcon = Visa; break;
    case 'whiteBag':
      SVGIcon = WhiteBag; break;
    case 'youtube':
      SVGIcon = Youtube; break;
    case 'zipLogo':
      SVGIcon = ZipLogo; break;
    case 'zip-min':
      SVGIcon = ZipMin; break;
    case 'zipMoneyLogo':
      SVGIcon = ZipMoneyLogo; break;
    case 'zipPayLogo':
      SVGIcon = ZipPayLogo; break;
    case 'iconArrow':
      SVGIcon = IconArrow; break;
    case 'iconDoubleQuptes':
      SVGIcon = IconDoubleQuptes; break;
    default:
      return <span>Unknown icon: {symbol}</span>
  }

  return <SVGIcon fill={fill} />
}

export default Icon
