import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import * as styles from './AccountPageMenu.module.css';

const AccountPageMenu = ({ isLoggedIn, firstName }) => {
  const menuItems = useMemo(() => {
    if (!isLoggedIn) {
      return [
        <Link to="/sign-in" key="sign-in">
          Sign in
        </Link>,
        <Link to="/create-account" key="create-account">
          Create Account
        </Link>
      ];
    }
    return [
      <Link to="/account/orders" key="orders">
        Orders
      </Link>,
      // <Link to="/account/#" key="messages">
      //   Messages
      // </Link>,
      <Link to="/account/wishlists" key="wishlists">
        Wishlists
      </Link>,
      <Link to="/account/addresses" key="addresses">
        Addresses
      </Link>,
      <Link to="/account/recent-items" key="recent-views">
        Recently Viewed
      </Link>,
      <Link to="/account/" key="my-details">
        My Details
      </Link>,
      <div className={styles.divider} key="divider" />,
      <Link to="/logout" key="logout">
        Log out
      </Link>
    ];
  }, [isLoggedIn]);

  return (
    <div className={styles.root}>
      {isLoggedIn && (
        <>
          <div className={styles.user}>
            Hi {firstName}!
          </div>
          <div className={styles.divider} />
        </>
      )}
      {menuItems}
    </div>
  );
};

export default AccountPageMenu;
