import React from 'react';

const ArrowRight = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="22.5" cy="22.5" r="22.5" transform="rotate(-90 22.5 22.5)" fill="#7B2CBF"/>
  <path d="M31.8075 23.0303C32.1004 22.7374 32.1004 22.2626 31.8075 21.9697L27.0345 17.1967C26.7416 16.9038 26.2668 16.9038 25.9739 17.1967C25.681 17.4896 25.681 17.9645 25.9739 18.2574L30.2165 22.5L25.9739 26.7426C25.681 27.0355 25.681 27.5104 25.9739 27.8033C26.2668 28.0962 26.7416 28.0962 27.0345 27.8033L31.8075 23.0303ZM12.5 23.25H31.2772V21.75H12.5V23.25Z" fill="white"/>
  </svg>
);

export default ArrowRight;
