import React, { useContext, useMemo, useState } from "react"
import { Link } from "gatsby"
import ContentfulContext from '../../../context/ContentfulProvider';
import * as styles from './Footer.module.css'
import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia'
// import UserQuickRegistration from '../../molecules/UserQuickRegistration/UserQuickRegistration'
import Container from '../../atoms/Container/Container'
import Button from '../../atoms/Button/Button'
import Logo from "../../atoms/Logo/Logo";
import Icon from "../../atoms/Icon/Icon";

import AccordionItem from '../../atoms/AccordionItem/AccordionItem';
import { getCookie } from "../../../helpers/general";
import { hubspotApi } from "../../../helpers/hubspot";
// TODO: Incomplete component - needs to be finished. Please update the readme when complete.
// TODO: Build out molecule/atom based components for below elements

const Footer = ({siteTitle, headerstyle, displaySubscribe}) => {
  // console.log(headerstyle);
  const contentful = useContext(ContentfulContext);
  // console.log(contentful);
  const menuData = useMemo(() => (contentful && contentful.footerMenu.map(item => {
    const menuLabel = item.menuItems[0].title;
    const menuLink = item.menuItems[0].url || item.menuItems[0].content.slug;
    const target = item.menuItems[0].linkTarget === 'New Tab';
    const location = item.menuItems[0].linkLocation === 'External';
    const subMenu = item.menuItems.slice(1)
    // console.log(subMenu);
    return {
      "menuLabel": menuLabel,
      "menuLink": menuLink,
      "target": target,
      "location": location,
      "submenu": subMenu
    };
  })) || [], [contentful]);

  const secondaryMenuData = useMemo(() => (contentful && contentful.secondaryFooterMenu.map(item => {
    return {
      "menuLabel": item.title,
      "menuLink": item.url || item.content.slug,
      "target": item.linkTarget === 'New Tab',
      "location": item.linkLocation === 'External',
      "submenu": null
    };
  })) || [], [contentful]);

  const [busy, setBusy] = useState(false);
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState({
    error: '',
    success: '',
  });
  const submitHandler = e => {
    if (!busy) {
      setBusy(true);
      e.preventDefault()

      if (email === '') {
        setMsg({error: 'Enter your email to subscribe.'})
        setBusy(false);
        return;
      } else {
        const body = {
          "fields": [
              {
                  "name": "email",
                  "value": email,
              },
          ],
          "context": {
            "hutk": getCookie('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          },
        }

        hubspotApi(`hubspot`, 'POST', body, true).then(response => {
          if ((response && response.status === 200) || response.status === 201) {
            setMsg({ success: 'Thank you for signing up!' });
            setTimeout(() => {
              setMsg({ success: '' })
            }, 10000)
            setBusy(false);
            setEmail('');
          } else {
            setMsg({
              error: 'Sorry, something went wrong. Try again later.'
            });
            setBusy(false);
          }
        });
      }
    }
  }

  const footerLink = (menuType, menuItem, key) => {
    if (menuItem.menuLink && menuItem.menuLink !== '') {
      // console.log(menuItem);
      return (
        <div className={styles.footerListStyle} key={key}>
          <AccordionItem key={key} title={<Link to={`/${menuItem.menuLink !== '/' ? menuItem.menuLink : ''}`}>{menuItem.menuLabel}</Link>} withIndicator={true} desktopDisplay='true' itemLength={menuItem.submenu.length}>
            <div>
            {menuItem.submenu.map((submenuItem, submenuItemKey) => <a href={`/${submenuItem.url}`} title={submenuItem.menuLabel} target={submenuItem.linkTarget==='Same Tab' ? '' : '_blank'} rel="noreferrer" key={submenuItemKey}>{submenuItem.title}</a>)}
            </div>
            </AccordionItem>
        </div>
      )
    }
    return null;
  }

  return (
    <footer>
      <div className={`${styles.footerTopWrapper} ${headerstyle || displaySubscribe ? headerstyle : styles.footerHidden}`}>
        <div className={styles.footerTopContent}>
          <div className={styles.footerListHeadingWrapper}><h5 className={styles.footerListHeading}>Subscribe to learn about our latest news, updates and showcases.</h5></div>
          <div className={styles.footerTopFormWrapper}>
            <form className={`${styles.footerTopForm} ${styles.footerListStyle}`} onSubmit={submitHandler}>
              <input type="text" id="subscribe" placeholder="Enter your email" value={email} onChange={(e) => {
                setEmail(e.target.value)
                setMsg({error: '', success: ''})
              }}/>
              {msg && (
                <div className={styles.responseContainer}>
                  {msg.error && <h5 className={styles.responseError}>{msg.error}</h5>}
                  {msg.success && <h5 className={styles.responseSuccess}>{msg.success}</h5>}
                </div>
              )}
              <Button level="primary" type="buttonSubmit"><Icon symbol="arrowRightLine" /></Button>
            </form>
          </div>
        </div>
      </div>
      <Container>
        <div className={styles.footerWrapper}>
          <div className={`${styles.footerListStyle} ${styles.footerLogo}`}>
            <Link to="/"><Logo siteTitle={siteTitle} /></Link>
          </div>
          
          {menuData.map((menuItem, menuItemKey) => footerLink('primary', menuItem, menuItemKey))}

          <div className={`${styles.footerListStyle} ${styles.footerListSocial}`}>
            <div className={`${styles.socialMediaContainer}`}><NavSocialMedia /></div>
            {/* <UserQuickRegistration /> */}
          </div>
        </div>
        <div className={`${styles.footerBottom} copyrightLine`}>
          <div className={styles.footerBottomListStyle}>
            LAVO System &copy; {new Date().getFullYear()}
          </div>
          <div className={styles.footerBottomListStyle}>
            {secondaryMenuData.map((menuItem, menuItemKey) => <Link to={`/${menuItem.menuLink}`} key={menuItemKey}>{menuItem.menuLabel}</Link>)}
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
