// extracted by mini-css-extract-plugin
export var productCard = "ProductCard-module--productCard---0-ab";
export var loading = "ProductCard-module--loading--cvuFg";
export var basicView = "ProductCard-module--basicView--etIEa";
export var fullView = "ProductCard-module--fullView--vcGfa";
export var fullImage = "ProductCard-module--fullImage--XJYoc";
export var productCardHead = "ProductCard-module--productCardHead--c-rUn";
export var productMainCardBody = "ProductCard-module--productMainCardBody--LJRa7";
export var imageContainer = "ProductCard-module--imageContainer--dV+QY";
export var flag = "ProductCard-module--flag--RcJJ9";
export var productImage = "ProductCard-module--productImage--atPrt";
export var imageSlider = "ProductCard-module--imageSlider--5fQqY";
export var productSliderImageWrapp = "ProductCard-module--productSliderImageWrapp--G4ds8";
export var productSliderImage = "ProductCard-module--productSliderImage--ji5lJ";
export var textBox = "ProductCard-module--textBox--VMgK1";
export var viewButton = "ProductCard-module--viewButton--xMk6o";
export var removeIcon = "ProductCard-module--removeIcon--ryoPZ";
export var productName = "ProductCard-module--productName--r3xoh";
export var price = "ProductCard-module--price--NUG9d";
export var onSale = "ProductCard-module--onSale--rwVQG";
export var salePrice = "ProductCard-module--salePrice--IBl2q";
export var productSku = "ProductCard-module--productSku---bCgr";
export var slickSlide = "ProductCard-module--slick-slide--4kyqi";
export var productDetails = "ProductCard-module--productDetails--t9e1J";
export var addCartButtonWrapp = "ProductCard-module--addCartButtonWrapp--wMzS3";
export var btnOutOfStock = "ProductCard-module--btnOutOfStock--FBkPw";