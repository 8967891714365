// extracted by mini-css-extract-plugin
export var modal = "KlarnaInfo-module--modal--ehbJu";
export var header = "KlarnaInfo-module--header--0VgA9";
export var intro = "KlarnaInfo-module--intro--z1EeY";
export var svgSection = "KlarnaInfo-module--svgSection--H+T6k";
export var titles = "KlarnaInfo-module--titles--tbwVk";
export var body = "KlarnaInfo-module--body--Mri7Z";
export var block = "KlarnaInfo-module--block--FWRf6";
export var imgContainer = "KlarnaInfo-module--imgContainer--NlLfO";
export var blockSubtitle = "KlarnaInfo-module--blockSubtitle--vpEIx";
export var blockMessage = "KlarnaInfo-module--blockMessage--SHfmp";
export var footer = "KlarnaInfo-module--footer--FX6vM";
export var bottomLine = "KlarnaInfo-module--bottomLine--yJEWL";
export var zipPay = "KlarnaInfo-module--zipPay--ou6Wz";
export var zipMoney = "KlarnaInfo-module--zipMoney--mbnq7";