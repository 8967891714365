/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useCallback} from 'react';
import clsx from 'clsx';
import Loader from '../../atoms/Loader/Loader';
import * as styles from './AdjustItem.module.css';
import { debounce } from 'lodash';

const AdjustItem = ({ item, updatingItem, updateCartItemQuantity, isCart, count, setItemCount }) => {
  const isUpdating = updatingItem === item.id;
  
  const [qty, setQty] = useState(item.quantity);

  // const sampleFunction = updateCartItemQuantity;

  // save previous state of item. this saves us from the infinite loop problem
  // const { current: prevItem } = useRef(item);
  // const { current: prevUpdateFunction } = useRef(updateCartItemQuantity);

  // useEffect(() => {
  //   // console.log(isUpdating);

  //   // if(isUpdating) return;
  //   // if(prevUpdateFunction === updateCartItemQuantity)

  //   if(qty !== prevItem.quantity && qty !== '' && qty !== '0') {
  //     const delayedUpdate = setTimeout(() => updateCartItemQuantity(prevItem, qty), 1000);
  //     return () => clearTimeout(delayedUpdate);
  //   }
  // }, [qty, prevItem]);

  const handler = useCallback(debounce((quantity) => {
    if(quantity !== '' && quantity !== '0') {
      updateCartItemQuantity(item, quantity);
    }
  }, 1000), []);

  const handleOnChange = (qty) => {
    handler(qty);
  }
  
  // For Cart Items
  const renderCartAdjustItem = () => {
    return (
      <div className={clsx(styles.root, isUpdating && 'disable-action')}>
        {/* <div
          data-button
          role="presentation"
          className={styles.AdjustButton}
          onClick={() => updateCartItemQuantity(item, 'minus')}
        >
          <div className={styles.addIconContainer}>
            <Icon symbol={'minus'}></Icon>
          </div>
        </div> */}

        <div className={styles.AdjustButton}>
          {isUpdating ? <Loader /> : 
            <input 
              type={'number'} 
              value={qty} 
              onChange={(e)=> {
                setQty(e.target.value);
                handleOnChange(e.target.value);
              }}
            >
            </input>
          }
        </div>

        {/* <div
          data-button
          role="presentation"
          className={styles.AdjustButton}
          onClick={() => updateCartItemQuantity(item, 'plus')}
        >
          <div className={styles.addIconContainer}>
            <Icon symbol={'add'}></Icon>
          </div>
        </div> */}
    </div>
    );
  }

  // Basic Count
  const renderBasicItemCount = () => {
    return (
      <div className={clsx(styles.root, isUpdating && 'disable-action')}>
        {/* <div
          data-button
          role="presentation"
          className={styles.AdjustButton}
          onClick={() => {
            if(count !== 0) {
              setItemCount(count - 1)
            }
          }}
        >
          <div className={styles.minusIconContainer}>
            <Icon symbol={'minus'}></Icon>
          </div>
        </div> */}

        <div className={styles.AdjustButton}>
          <input type={'number'} value={count} onChange={(e)=>setItemCount(e)}></input>
        </div>

        {/* <div
          data-button
          role="presentation"
          className={styles.AdjustButton}
          onClick={() => setItemCount(count + 1)}
        >
          <div className={styles.addIconContainer}>
            <Icon symbol={'add'}></Icon>
          </div>
        </div> */}
    </div>
    );
  }

  return (isCart ? renderCartAdjustItem() : renderBasicItemCount());
};

export default AdjustItem;
