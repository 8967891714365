// extracted by mini-css-extract-plugin
export var root = "AccordionItem-module--root--lxFCJ";
export var iconContainer = "AccordionItem-module--iconContainer--9o7Bh";
export var accordionHeader = "AccordionItem-module--accordionHeader--alnB6";
export var accordionContent = "AccordionItem-module--accordionContent--kKv5u";
export var hide = "AccordionItem-module--hide--QOJNF";
export var show = "AccordionItem-module--show--adQ0m";
export var accordionTitle = "AccordionItem-module--accordionTitle--ERl-y";
export var border = "AccordionItem-module--border--bBQB-";
export var desktopIconHidden = "AccordionItem-module--desktopIconHidden--rom2m";
export var desktopDisplay = "AccordionItem-module--desktopDisplay--KB3YH";