// extracted by mini-css-extract-plugin
export var modal = "ZipPayInfo-module--modal--YAfV7";
export var header = "ZipPayInfo-module--header--kM5LQ";
export var intro = "ZipPayInfo-module--intro--iiKuP";
export var svgSection = "ZipPayInfo-module--svgSection--aU3JN";
export var titles = "ZipPayInfo-module--titles--6OIfx";
export var body = "ZipPayInfo-module--body--mZyy-";
export var block = "ZipPayInfo-module--block--t0c+L";
export var imgContainer = "ZipPayInfo-module--imgContainer--7bpxv";
export var blockSubtitle = "ZipPayInfo-module--blockSubtitle--95xu5";
export var blockMessage = "ZipPayInfo-module--blockMessage--ZC2nx";
export var footer = "ZipPayInfo-module--footer--kclXJ";
export var bottomLine = "ZipPayInfo-module--bottomLine--pjIko";
export var zipPay = "ZipPayInfo-module--zipPay--34TT6";
export var zipMoney = "ZipPayInfo-module--zipMoney--eL2bX";