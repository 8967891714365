
import React from 'react';

const ArrowRightLong = ({ fill }) => (
  <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8075 6.53033C20.1004 6.23744 20.1004 5.76256 19.8075 5.46967L15.0345 0.696699C14.7416 0.403806 14.2668 0.403806 13.9739 0.696699C13.681 0.989593 13.681 1.46447 13.9739 1.75736L18.2165 6L13.9739 10.2426C13.681 10.5355 13.681 11.0104 13.9739 11.3033C14.2668 11.5962 14.7416 11.5962 15.0345 11.3033L19.8075 6.53033ZM0.5 6.75H19.2772V5.25H0.5V6.75Z" fill={fill || 'white'} />
  </svg>
);

export default ArrowRightLong;
