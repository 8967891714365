/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import BindContext from '../../../context/BindProvider';

import { setStorage } from '../../../helpers/general';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// CSS not modular here to provide global styles
import './Layout.css';
import './Globals.css';
import './Variables.css';
import './Common.css';

const Layout = ({
  headerstyle,
  children,
  slim = false,
  varHelmet,
  genClass,
  layoutStyle,
  withFluidImg = false,
  displaySubscribe,
  pagePadding,
}) => {
  const bind = useContext(BindContext);
  const initBind = bind && bind.init;
  const pageTitle = (varHelmet && varHelmet.pageTitle) || false;
  const metaTitle = (varHelmet && varHelmet.metaTitle) || false;
  const metaDescription = (varHelmet && varHelmet.metaDescription) || false;
  const metaCanonicalLink = (varHelmet && varHelmet.metaCanonicalLink) || false;

  // const [messages, setMessages] = useState([]);
  // const [fetchedMessages, setFetchedMessages] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (
    typeof window !== 'undefined' &&
    [
      '/sign-in',
      '/sign-in/',
      '/create-account',
      '/create-account/',
      '/forgotpassword',
      '/forgotpassword/',
    ].indexOf(window.location.pathname) === -1
  ) {
    setStorage('lastPage', window.location.href, true);
  }

  // useEffect(() => {
  //   if (messages.length === 0 && !fetchedMessages) {
  //     setFetchedMessages(true);
  //     const fetchedMessages = data.allBuilderModels.globalInfoBannerContent.map((message) => {
  //       return {
  //         text: message.data.text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'),
  //         link: message.data.link,
  //       }
  //     });
  //     setMessages(fetchedMessages);
  //   }
  //   initBind()
  // }, [initBind, data, messages, setMessages, fetchedMessages, setFetchedMessages])

  const messages = [
    { text: 'This is a hardcoded example message', link: '/shop/shop-all' },
  ];

  // useEffect(() => {
  //   initBind()
  // }, [initBind])

  return (
    <>
      <Helmet
        title={pageTitle || 'Lavo'}
        meta={[
          { property: 'title', content: metaTitle },
          { property: 'og:title', content: metaTitle },
          { property: 'description', content: metaDescription },
          { property: 'canonical', content: metaCanonicalLink },
        ]}></Helmet>
      <Header
        headerstyle={headerstyle}
        siteTitle={pageTitle || 'Lavo'}
        messages={messages}
      />
      {headerstyle === 'dark' ? (
        <main
          className={`${
            headerstyle === 'dark'
              ? 'layoutDark'
              : `pageGeneral layoutGeneral ${withFluidImg ? 'fullHeight' : ''}`
          } ${genClass ? genClass : ''} ${pagePadding ? 'pagePadding' : ''}`}
          data-layout={layoutStyle}>
          {children}
          {!slim && (
            <Footer
              siteTitle={data.site.siteMetadata?.title || `Title`}
              headerstyle={headerstyle}
              displaySubscribe={displaySubscribe}
            />
          )}
        </main>
      ) : (
        <>
          <main
            className={`${
              headerstyle === 'dark'
                ? 'layoutDark'
                : `pageGeneral layoutGeneral ${
                    withFluidImg ? 'fullHeight' : ''
                  }`
            } ${genClass ? genClass : ''} ${pagePadding ? 'pagePadding' : ''}`}
            data-layout={layoutStyle}>
            {children}
          </main>
          {!slim && (
            <Footer
              siteTitle={data.site.siteMetadata?.title || `Title`}
              headerstyle={headerstyle}
              displaySubscribe={displaySubscribe}
            />
          )}
        </>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

