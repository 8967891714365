import React from 'react'

const Logo = ({ fill, siteTitle }) => (
    <svg width="158" height="26" viewBox="0 0 158 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{siteTitle}</title>
        <path d="M18.8023 21.9165V25.6602H1.99898C0.915187 25.6602 0 24.7407 0 23.652V0.476555H3.7268V21.9165H18.8023Z" fill={fill || '#241C2C'}/>
        <path d="M58.9881 25.6602L51.1287 7.01056L43.1673 25.6602H39L49.3672 1.29365C49.6718 0.545022 50.3833 0.102726 51.1623 0.102726C51.9421 0.102726 52.5852 0.545022 52.8902 1.29365L63.1551 25.6602H58.9881" fill={fill || '#241C2C'}/>
        <path d="M104.053 0.476115L93.7886 24.7749C93.4834 25.4896 92.8405 25.9658 92.0604 25.9658C91.2817 25.9658 90.5702 25.4896 90.2656 24.7749L79.8984 0.476115H83.9302L91.9932 19.432L100.022 0.476115H104.053" fill={fill || '#241C2C'}/>
        <path d="M145.557 12.9322C145.592 7.75919 141.458 3.67546 136.444 3.67546C131.396 3.67546 127.263 7.75919 127.297 12.9322C127.297 18.0706 131.396 22.325 136.444 22.325C141.492 22.325 145.557 18.1051 145.557 12.9322V12.9322ZM123.57 12.9322C123.57 5.68347 129.329 0.000396167 136.444 0.000396167C143.423 0.000396167 149.284 5.61566 149.284 12.9322C149.284 20.181 143.558 26 136.444 26C129.329 26 123.57 20.1471 123.57 12.9322" fill={fill || '#241C2C'}/>
        <path d="M117.24 15.3955C115.923 15.3955 114.855 14.323 114.855 12.9999C114.855 11.677 115.923 10.6045 117.24 10.6045C118.557 10.6045 119.625 11.677 119.625 12.9999C119.625 14.323 118.557 15.3955 117.24 15.3955" fill={fill || '#241C2C'}/>
        <path d="M155.615 15.3955C154.298 15.3955 153.23 14.323 153.23 12.9999C153.23 11.677 154.298 10.6045 155.615 10.6045C156.932 10.6045 158 11.677 158 12.9999C158 14.323 156.932 15.3955 155.615 15.3955" fill={fill || '#241C2C'}/>
    </svg>
)

export default Logo