import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import * as styles from './AccordionItem.module.css';

const AccordionItem = ({ title, itemKey, children, desktopDisplay, itemLength }) => {

  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.accordionHeader} role='presentation' data-accordion={itemKey} onClick={() => setIsActive(!isActive)}>
        <span className={styles.accordionTitle}>{title}</span>
        {itemLength > 0 &&
          <div className={`${styles.iconContainer} ${desktopDisplay !== true ? styles.desktopIconHidden : '' }`}>
            <Icon symbol={isActive === true ? 'minus' : 'add'}></Icon>
          </div>
        }

      </div>
      <div className={styles.border}></div>
      <div className={`${styles.accordionContent} ${isActive !== true ? styles.hide : styles.show } ${desktopDisplay !== true ? styles.desktopDisplay : '' }`}>
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
