import React from "react"
import ProductCard from "../../molecules/ProductCard/ProductCard"

import * as styles from'./ProductGrid.module.css'

const ProductGrid = ({ products, removeFunction }) => {
    if (products) {
        return (
            <div className={styles.root}>
                {products.map((node, index) => {
                    if ('node' in node) {
                        return <ProductCard key={index} data={node.node} removeFunction={removeFunction} />
                    }
                    if ('sku' in node) {
                        return <ProductCard key={index} data={node} removeFunction={removeFunction} />
                    }
                    return null
                })}
            </div>
        )
    } else {
        return null;
    }
}

export default ProductGrid
