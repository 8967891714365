import React, { useEffect, useState, useRef } from 'react'; // useRef, useContext
// import parse from 'html-react-parser';
import { has } from 'lodash';
import Container from '../../atoms/Container/Container';
import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import Price from '../../molecules/Price/Price';
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';
import StockStatus from '../../molecules/StockStatus/StockStatus';
import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Bnpl from '../../atoms/BNPL/BNPL';
import AdjustItem from '../AdjustItem/AdjustItem';
// import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

/* Enable Fast Checkout */
// import FastCheckout from '../../atoms/FastCheckout/FastCheckout';

/* LOCALISR & Stock availability functionality */
// import Localisr from '../../molecules/Localisr/Localisr';

/* BC Reviews functionality */
// import ReviewContext from '../../../context/ReviewProvider';
// import Reviews from '../../organisms/Reviews/Reviews';

import * as styles from './ProductDetails.module.css'

const ProductDetails = props => {
    const { product, type, stickyInfo } = props;

    const [maxQtyMessage, setMaxQtyMessage] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [priceObj, setPriceObj] = useState(product || {});
    const [stockObj, setStockObj] = useState({});
    const [inStock, setInStock] = useState({});
    // const [warranty, setWarrantyOption] = useState(false);
    const warranty = false;
    const [activeVariant, setActiveVariant] = useState(false);
    const [ctl, setCtl] = useState([]);
    const [pageInit, setPageInit] = useState(false);

    const shippingOptionsAnchor = useRef();

    /* LOCALISR & Stock availability functionality */
    // const ctxCart = useContext(CartContext);
    // const ctxUpdateStockAvailability = get(ctxCart, 'updateStockAvailability');
    // const [skuSACheck, setSkuSACheck] = useState(false);
    // const ctxSelectedStore = get(ctxCart, 'state.selectedStore');
    // const defaultStore = {
    //     store_id: (ctxSelectedStore && ctxSelectedStore.store_id) || '',
    //     store_name: (ctxSelectedStore && ctxSelectedStore.store_name) || '',
    //     store_address: (ctxSelectedStore && ctxSelectedStore.store_address) || '',
    //     store_location: (ctxSelectedStore && ctxSelectedStore.store_location) || '',
    // };
    // const [selectedStore, setSelectedStore] = useState({...defaultStore});

    // useEffect(() => {
    //     if (ctxSelectedStore && ctxSelectedStore.store_id && setSelectedStore) {
    //         setSelectedStore(ctxSelectedStore);
    //     }
    // }, [ctxSelectedStore, setSelectedStore]);
    // useEffect(() => {
    //     const sku = 'sku' in stockObj ? stockObj.sku : false;
    //     if (sku && skuSACheck !== sku) {
    //         setSkuSACheck(sku);
    //         exampleApi(`path/to/stock/${sku}`).then(({response, status}) => {
    //             if (typeof response === 'object' && 'data' in response && 'stock' in response.data) {
    //                 ctxUpdateStockAvailability(response.data.stock);
    //             }
    //         });
    //     }
    // }, [stockObj, skuSACheck, ctxUpdateStockAvailability]);
    
    /* BC Reviews functionality */
    // const ctxReview = useContext(ReviewContext);
    // const showReviewForm = ctxReview && ctxReview.showReviewForm;

    /* Sticky Add to cart element */
    const [stickyCart, setStickyCart] = useState(false);
    const [stickyCartMobile, setStickyCartMobile] = useState(false);
    // listen to anchor tags - sticky headers
    useEffect(() => {
        const handleScroll = () => {
            const { offsetTop } = shippingOptionsAnchor.current;
            
            if( window.scrollY > offsetTop ) {
                setStickyCart(true);
                setStickyCartMobile(true);
            } else {
                setStickyCart(false);
                setStickyCartMobile(false);
            }

        };
    
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

    const inventoryCount =
        product &&
        typeof product.inventory_tracking !== 'undefined' &&
        typeof product.inventory_level !== 'undefined' &&
        typeof product.inventory_warning_level !== 'undefined' &&
        product.inventory_tracking === 'product' &&
        product.inventory_level;

    // const selectVariant = variant => {
    //     setActiveVariant(variant.entityId);
    //     setPriceObj({ ...variant, priceOptions: {override: true} });
    //     setStockObj({ ...variant, availability: product.availability, detail: {Call_For_Availability: product.detail.Call_For_Availability}});
    // }

    useEffect(() => {
        if (product &&
              typeof product.inventory_tracking !== 'undefined' &&
              typeof product.inventory_level !== 'undefined' &&
              typeof product.inventory_warning_level !== 'undefined' &&
              product.inventory_tracking === 'product' &&
              product.inventory_level < 1
        ) {
            console.log('No inventory found', product);
            setQuantity(0);
        }

        // Set active variant
        if (product && product.variants.length > 0 && !activeVariant) {
            setActiveVariant(product.variants[0].entityId);
            setPriceObj({ ...product.variants[0], priceOptions: {override: product.variants.length > 1 ? true : false} });
            setStockObj({ ...product.variants[0], availability: product.availability, detail: {Call_For_Availability: product.detail.Call_For_Availability}});
        } else if (product && product.variants.length === 0 && !activeVariant) {
            setActiveVariant(true);
            setStockObj(product);
        }

        // Set Complete the look
        if (product && 'completeTheLook' in product && product.completeTheLook.length > 0 && ctl.length === 0) {
            let randomIndex = 0;
            let randomiseProducts = product.completeTheLook;
            let currentIndex = randomiseProducts.length;
    
            while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
    
            [randomiseProducts[currentIndex], randomiseProducts[randomIndex]] = [
                randomiseProducts[randomIndex], randomiseProducts[currentIndex]];
            }
    
            setCtl(randomiseProducts.length > 1 ? randomiseProducts : false);
        }

        // Page init functions
        if (!pageInit) {
            setPageInit(true);
            // Open first description accordion at page load
            const description = document.querySelector('[data-description]');
            if (description) {
                const h4s = description.querySelectorAll('h4');
                if (h4s.length > 0) {
                    h4s[0].classList.toggle('open');
                    let nextElement = h4s[0].nextElementSibling;
                    while (nextElement&& !nextElement.matches('h4')) {
                        nextElement.classList.toggle(styles.active);
                        nextElement = nextElement.nextElementSibling;
                    }
                }
            }
        }
    }, [product, ctl, pageInit, activeVariant]);

    const updateQuantity = e => {
        let quantity = e.target.value;
        let maxQtyMessage = false;
    
        if (inventoryCount && quantity > inventoryCount) {
          quantity = inventoryCount;
          maxQtyMessage = `Only ${inventoryCount} available`;
        } else if (quantity < 1) {
          quantity = 1;
        }
    
        setMaxQtyMessage(maxQtyMessage);
        setQuantity(quantity);
    }
    
    const onClickDesc = evt => {
        if (evt.target.matches('h4')) {
            const toOpen = !evt.target.classList.contains('open');
            // minimise already open accordion
            const description = document.querySelector('[data-description]');
            [...description.querySelectorAll('h4')].map(h4 => {
                h4.classList.remove('open');
                return true;
            });
            [...description.querySelectorAll(`.${styles.active}`)].map(ele => {
                ele.classList.remove(styles.active);
                return true;
            });
            // toggle clicked 
            if (toOpen) {
                evt.target.classList.add('open');
                let nextElement = evt.target.nextElementSibling;
                while (nextElement && !nextElement.matches('h4')) {
                    nextElement.classList.add(styles.active);
                    nextElement = nextElement.nextElementSibling;
                }
            }

            setTimeout(() => {
                const rect = description.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop,
                    offset = document.querySelector('[data-header]').offsetHeight;
                const position = { top: (rect.top + scrollTop) - offset, left: rect.left + scrollLeft };
                
                window.scrollTo({
                    top: position.top,
                    behavior: 'smooth',
                });
            }, 350);
        }
    };
    
    const onStatusUpdate = (_status) => {
        setInStock(_status)
    }

    const triggerReviewsView = () => {
        if (typeof window !== 'undefined') {
            const productInfo = document.getElementById('reviews');
            // const reviewTab = productInfo.querySelector('[data-tab="reviews"]');
            // const reviewAccordion = productInfo.querySelector('[data-accordion="reviews"]');
            
            window.scrollTo({
                top: productInfo.offsetTop - 100,
                behavior: 'smooth'
            });

            // reviewTab.click();
            // reviewAccordion.click();
        }
    }

    const formatScore = score => {
        if (!Number.isInteger(score)) {
            return parseFloat(score).toFixed(1);
        }

        return score;
    }

    return (
        <div className={styles.productDetails}>
            {/* Brand */}
            {/* {brandImage && (
                <div className={styles.brandLogoContainer}>
                    <img src={brandImage} alt='brand_logo' />
                </div>
            )} */}

            {/* Title Container */}
            <div className={styles.titleContainer}>
                <div>
                    <h1 className="h3">{product && product.name}</h1>
                </div>
                <div className={styles.wishIconContainer}>
                    <div className={styles.wishIcon}>
                        <WishlistButton 
                            productId={product.entityId} 
                            variantId={activeVariant} 
                        />
                    </div>
                </div>
            </div>

            {/* SKU */}
            <div className={styles.skuContainer}>
                <span>SKU: {product.sku}</span>
            </div>

            {/* Review and Inventory Check */}
            <div className={styles.reviewInventoryCheck}>
                <div className={styles.reviewContainer} role="presentation" onClick={() => triggerReviewsView()}>
                    <Icon symbol='star'></Icon>
                    <span className={styles.rating}>{(product && product.rating && product.rating.score !== 'None' && formatScore(product.rating.score)) || 'None'}</span>
                    <span>({(product && product.rating && product.rating.totalReviews) || '0'} reviews)</span>
                </div>
                <div className={styles.inventoryCheckContainer}>
                    <StockStatus key="status" product={stockObj || product} onStatusUpdate={onStatusUpdate} />
                </div>
            </div>
            
            {/* Price */}
            <Price
                styles={styles}
                entityId={priceObj.product_id} 
                price={priceObj.price} 
                calculated_price={priceObj.calculated_price} 
                sale_price={priceObj.sale_price}
                options={priceObj.priceOptions}
            />

            {/* Payment Options */}
            <Bnpl product={product} suppliers={['afterpay', 'klarna', 'paypal', 'zip']} />

            <div key="warranty" className={styles.warranty}>{product.warranty}</div>

            {/* PRODUCT VARIANTS - P2C */}
            {product.variants.length > 1 && (
                <div className={styles.variantOptionsContainer}>
                    <span className={styles.optionsTitle}>Options</span>
                    {/* SWATCHES */}
                    {/*product.detail.values.colour && (
                        <div className={styles.variantSwatches}>
                            <a href={product.custom_url.url} className={`${styles.swatch} ${styles.active}`}>
                                <span style={{backgroundColor: product.refEntities.find(rf => rf.entity === 'colours' && rf.code === product.detail.values.colour[0].data)?.values.hex[0]?.data}}>
                                    {fetchTitle(product)}
                                </span>
                            </a>
                        
                            {product.siblings.map((sibling, siblingIndex) => {
                                const details = product.siblingDetail.find(sd => sd.sku === sibling.node.sku);
                                if (details && details.values.colour) {
                                    const hex = product.refEntities.find(rf => rf.entity === 'colours' && rf.code === details.values.colour[0].data)?.values.hex[0].data;

                                    return (
                                        <a key={siblingIndex} href={sibling.node.custom_url.url} className={styles.swatch}>
                                            <span style={{backgroundColor: hex}}>
                                                {fetchTitle(sibling.node, details)}
                                            </span>
                                        </a>
                                    )
                                } else if (details) {
                                    return (
                                        <a key={siblingIndex} href={sibling.node.custom_url.url} className={styles.option}>
                                            {fetchTitle(sibling.node, details)}
                                        </a>
                                    )
                                }

                                return null;
                            })}
                        </div>
                    )}

                    {/* DROPDOWN */}
                    {/*!product.detail.values.colour && ( */}
                    {/* <div className={styles.variantOptionsWrap}>
                        <div className={styles.variantOptions}>
                            <Icon symbol='arrowDown' />
                            {product.variants.map((variant, variantIndex) => {
                                const details = product.variantDetail.find(vd => vd.sku === variant.sku);
                                if (details) {
                                    return (
                                        <span role="presentation" key={variantIndex} className={`${styles.option} ${activeVariant === variant.entityId ? styles.active : ''}`} onClick={() => selectVariant(variant)}>
                                            {variant.option_values.map(option => option.label).join(' ')}
                                        </span>
                                    )
                                }
                                return null;
                            })}
                        </div>
                    </div> */}
                </div>
            )}

            {/* Checkout Container */}
            {product.availability === 'disabled' ? (
                <div className={styles.addToCartContainer} ref={shippingOptionsAnchor}>
                    <Button level="primary" size='fullWidth' href="/contact-us/">Contact us</Button>
                </div>
            ) : (
                <>
                    <div className={styles.checkoutContainer}>
                        <div className={styles.checkoutContent}>
                            <div className={styles.qtyContainer}>
                                <AdjustItem isCart={false} count={quantity} setItemCount={updateQuantity} item={product}></AdjustItem>
                            </div>
                            {/* Add to Cart Button */}
                            <div className={styles.addToCartContainer}>
                                <AddToCartButton fullWidth productId={product.entityId}
                                    variantId={activeVariant}
                                    quantity={quantity}
                                    extraItems={warranty ? [{productId: warranty, matchQty: true}] : []}>
                                        {(inStock?.available === "no-stock") ? 'Out of Stock' : (
                                            (inStock?.available === "po-stock") ? 'Pre-Order' : "Add to cart"
                                        )}
                                </AddToCartButton>
                            </div>
                        </div>
                        {maxQtyMessage && (
                            <span className={styles.maxQtyMessage}>
                                {maxQtyMessage}
                            </span>
                        )}
                    </div>

                    {/* Fast Checkout Button */}
                    {/* <div className={styles.fastCheckoutButtonContainer}>
                        <FastCheckout product_id={product.entityId} variant_id={activeVariant} quantity={quantity} warranty={warranty} withOr="top" withFastInfo={true} />
                    </div> */}

                    {/* Shipping Options */}
                    <div className={styles.shippingOptionsContainer} ref={shippingOptionsAnchor}>
                        {/* <ShippingEstimates product={stockObj} />
                        <div className={styles.shippingBorder}></div>
                        <div className={styles.shippingOption}>
                            <div className={styles.collectContainer}>
                                <div className={styles.collectIconsContainer}>
                                    <div className={styles.purseIconContainer}>
                                        <Icon symbol='purse'></Icon>
                                    </div>
                                    <div className={styles.clickIconContainer}>
                                        <Icon symbol='click'></Icon>
                                    </div>
                                </div>
                                <span>Click & Collect</span>
                            </div>
                            <div className={styles.storeAvailability}>
                                <Localisr text="Check store availability." products={[stockObj]} />
                            </div>
                        </div>
                        {selectedStore.store_id !== '' && (
                            <div className={styles.storeDetail}>
                                <ItemStatus item={stockObj} forceDisplay={true} />
                                <span className={styles.storeName}>{selectedStore.store_name}</span>
                                <span className={styles.storeAddress}>{selectedStore.store_address}</span>
                            </div>
                        )} */}
                    </div>
                </>
            )}

            {type === "full" && (
                <>
                    {'description' in product && (
                        <div
                            key="description"
                            className={styles.productDescription}
                            onClick={onClickDesc}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex="0"
                            data-description
                        >
                            <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                        </div>
                    )}
                    
                    {('completeTheLook' in product && product.completeTheLook.length > 0 && ctl) && (
                        <div key="completeTheLook" className={styles.completeTheLook}>
                            <h3>Complete the Look</h3>
                            <ProductGrid products={ctl.slice(0, 4)} columns="2" loadInventory={true} />
                        </div>
                    )}
                </>
            )}

            {/* Sticky Header - Web */}
            <div className={`
            ${styles.stickyAddToCart}
            ${stickyCart === true ? styles.show : ''}
            `}
            >
                <Container size="large">
                <div className={`
                    ${styles.stickAddToCartContent}
                    ${stickyInfo === true ? styles.border : ''}
                    `}>
                    {/* Left Side Container */}
                    <div className={styles.stickyAddToCartLeft}>
                        {/* Image Container */}
                        <div className={styles.stickyAddToCartImageContainer}>
                            <img alt={'product_image_on_cart'} src={
                                has(product.images, 'node')
                                ? product.images.node.url
                                : has(product.images, 'url_zoom')
                                ? product.images.url_zoom
                                : product.images.url_standard
                            } />
                        </div>
                        {/* Details Container */}
                        <div className={styles.stickAddToCartDetails}>
                            {priceObj && priceObj.sale_price > 0 && (
                                <span className={styles.onSaleTag}>ON SALE</span>
                            )}
                            <span className={styles.productTitleSticky}>{product && product.name}</span>
                            <Price
                                styles={styles}
                                entityId={priceObj.product_id} 
                                price={priceObj.price} 
                                calculated_price={priceObj.calculated_price} 
                                sale_price={priceObj.sale_price}
                            />
                        </div>
                    </div>

                    {/* Right Side Container */}
                    <div className={styles.stickyAddToCartRight}>
                        <div className={styles.stickyPaymentOptionContainer}>
                            <Bnpl product={product} suppliers={['afterpay', 'klarna', 'paypal', 'zip']} logosOnly={true} />
                        </div>
                        {product.availability === 'disabled' ? (
                            <Button level="primary" href="/contact-us/">Contact us</Button>
                        ) : (
                            <>
                                <AdjustItem 
                                    isCart={false} 
                                    count={quantity} 
                                    setItemCount={(e) => updateQuantity(e)} 
                                    item={product} 
                                />
                                <div className={styles.stickyAddToCartButtonContainer}>
                                    <AddToCartButton productId={product.entityId}
                                        variantId={activeVariant}
                                        quantity={quantity}
                                        extraItems={warranty ? [{productId: warranty, matchQty: true}] : []}>
                                            {(inStock?.available === "no-stock") ? 'Out of Stock' : (
                                                (inStock?.available === "po-stock") ? 'Pre-Order' : "Add to cart"
                                            )}
                                    </AddToCartButton>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                </Container>
            </div>

            {/* Sticky Footer - Mobile */}
            <div className={`
                ${styles.stickyFooter}
                ${stickyCartMobile === true ? styles.stickyFooterActive : ''}
            `}>
                <AdjustItem 
                    isCart={false} 
                    count={quantity} 
                    setItemCount={(e) => updateQuantity(e)} 
                    item={product} 
                />
                <div className={styles.stickyFooterButtonContainer}>
                    <AddToCartButton productId={product.entityId}
                        fullWidth
                        variantId={activeVariant}
                        quantity={quantity}
                        extraItems={warranty ? [{productId: warranty, matchQty: true}] : []}>
                            {(inStock?.available === "no-stock") ? 'Out of Stock' : (
                                (inStock?.available === "po-stock") ? 'Pre-Order' : "Add to cart"
                            )}
                    </AddToCartButton>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails