import React from 'react'

const Logo = ({ fill, siteTitle }) => (
    <svg data-mobile width="44" height="26" viewBox="0 0 44 26" fill="none" xmlns="http://www.w3.org/2000/svg"><title>{siteTitle}</title>
    <path d="M31.5571 12.9322C31.5915 7.75919 27.4581 3.67546 22.4441 3.67546C17.3959 3.67546 13.2633 7.75919 13.2972 12.9322C13.2972 18.0706 17.3959 22.325 22.4441 22.325C27.4917 22.325 31.5571 18.1051 31.5571 12.9322V12.9322ZM9.57031 12.9322C9.57031 5.68347 15.3294 0.000396167 22.4441 0.000396167C29.4232 0.000396167 35.2836 5.61566 35.2836 12.9322C35.2836 20.181 29.5581 26 22.4441 26C15.3294 26 9.57031 20.1471 9.57031 12.9322" fill={fill || 'black'}/>
    <path d="M3.2402 15.3955C1.92313 15.3955 0.855469 14.323 0.855469 12.9999C0.855469 11.677 1.92313 10.6045 3.2402 10.6045C4.55728 10.6045 5.62495 11.677 5.62495 12.9999C5.62495 14.323 4.55728 15.3955 3.2402 15.3955" fill={fill || 'black'}/>
    <path d="M41.6152 15.3955C40.2981 15.3955 39.2305 14.323 39.2305 12.9999C39.2305 11.677 40.2981 10.6045 41.6152 10.6045C42.9323 10.6045 43.9999 11.677 43.9999 12.9999C43.9999 14.323 42.9323 15.3955 41.6152 15.3955" fill={fill || 'black'}/>
    </svg>
)

export default Logo