import React from 'react'

const Hamburger = () => (
    <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="1" x2="30" y2="1" stroke="black" strokeWidth="2"/>
    <line y1="6.4" x2="30" y2="6.4" stroke="black" strokeWidth="2"/>
    <line y1="12" x2="30" y2="12" stroke="black" strokeWidth="2"/>
    </svg>
)

export default Hamburger