import React from "react"
import { isNumeric } from "../../../helpers/general";
const CurrencyFormatter = ({ currency, amount, appendZero = false }) => {

  let displayAmount =  (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';
  
  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  });
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(obj => obj.type === 'currency');
    const decimalValue = formattedPriceParts.find(obj => obj.type === 'fraction');
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  }

  const priceComponent = 
    <>
      <span>$</span>
      <span>{formattedPrice}</span>
    </>;

return isNumeric(amount) ? priceComponent : 'No price available'
};
  
export default CurrencyFormatter;