// extracted by mini-css-extract-plugin
export var modal = "AfterPayInfo-module--modal--GCNzt";
export var header = "AfterPayInfo-module--header--BZvkv";
export var intro = "AfterPayInfo-module--intro--I4st9";
export var svgSection = "AfterPayInfo-module--svgSection--Sc3WK";
export var titles = "AfterPayInfo-module--titles--I0cSn";
export var body = "AfterPayInfo-module--body--Dn3vd";
export var block = "AfterPayInfo-module--block--iiktS";
export var imgContainer = "AfterPayInfo-module--imgContainer--LymqF";
export var blockSubtitle = "AfterPayInfo-module--blockSubtitle--EmGTG";
export var blockMessage = "AfterPayInfo-module--blockMessage--7dtcd";
export var footer = "AfterPayInfo-module--footer--6aj94";
export var bottomLine = "AfterPayInfo-module--bottomLine--Q6UxF";