/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { JsonLd, Generic } from 'react-structured-data'

function Seo({ description, ogDescription, twDescription, lang, meta, title, ogTitle, twTitle, children, defaultMicroData = true, microDataType, image='' }) {

  const metaDescription = description || ''
  const siteName = "LAVO"
  const imageUrl = image || '';
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={siteName ? `%s | ${siteName}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: `${ogTitle || title} | ${siteName}`,
          },
          {
            property: `og:description`,
            content: ogDescription || metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: imageUrl,
          },
          {
            property: `og:image:secure_url`,
            content: imageUrl,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: '',
          },
          {
            name: `twitter:title`,
            content: `${twTitle || ogTitle || title} | ${siteName}`,
          },
          {
            name: `twitter:description`,
            content: twDescription || ogDescription || metaDescription,
          },
          {
            name: `twitter:image`,
            content: imageUrl,
          }
        ].concat(meta)}
      >{children}
        <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/8385778.js"></script>
      </Helmet>

      {/* GENERAL PAGES */}
      {(defaultMicroData && !microDataType) && (
        <></>
        // TODO: Throwing an error for some random reason - look further into this
        // <JsonLd>
        //   <Generic type="webpage" jsonldtype="WebPage" schema={{
        //     name: title, 
        //     description: metaDescription
        //   }}/>
        // </JsonLd>
      )}

      {/* HOMEPAGE */}
      {(defaultMicroData && microDataType === 'home') && (
        <JsonLd>
          <Generic type="store" jsonldtype="Store" schema={{
            name: title, 
            url: typeof window !== 'undefined' && window.location.href,
            logo: `${process.env.SITE_URL}/logo.png`,
            telephone: '',
            priceRange: "$$$",
            image: `${process.env.SITE_URL}/logo.png`,
          }}>
            <Generic type="potentialAction" jsonldtype="SearchAction" schema={{
              "target":`${process.env.SITE_URL}/search/?q={search_term}`,
              "query-input": "required name=search_term"
            }}/>
            <Generic type="address" jsonldtype="PostalAddress" schema={{
              streetAddress: '',
              addressLocality: '',
              postalCode: '',
              addressCountry: '',
            }}/>
          </Generic>
        </JsonLd>
      )}
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
