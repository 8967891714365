import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import ContentfulContext from '../../../context/ContentfulProvider';
// import { Link, useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Container from '../../atoms/Container/Container';

import * as styles from './SiteMenu.module.css';
// import { $CombinedState } from 'redux';
// TODO: Look to replace this default with data from an external system, in the same structure.
// import defaultMenuItems from './example.json'

const SiteMenu = props => {
  const { setNavOpen } = props;
  const [activeNav, setActiveNav] = useState(0);
  const [activeMenu, setActiveMenu] = useState([]);
  const contentful = useContext(ContentfulContext);

  const boxRef = useRef([]);
  const [isOffset, setOffset] = useState(0);
  const [isTabletView, setIsTabletView] = useState();

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth <= 1000);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getPositionX = i => {
    setOffset([boxRef.current[i].offsetLeft]);
  };
  const menuData = useMemo(
    () =>
      (contentful &&
        contentful.footerMenu.map(item => {
          const menuLabel = item.menuItems[0].title;
          const menuLink =
            item.menuItems[0].url || item.menuItems[0].content.slug;
          const target = item.menuItems[0].linkTarget === 'New Tab';
          const location = item.menuItems[0].linkLocation === 'External';
          const subMenu = item.menuItems.slice(1);
          // console.log();
          return {
            menuLabel: menuLabel,
            menuLink: menuLink,
            target: target,
            location: location,
            submenu: subMenu,
          };
        })) ||
      [],
    [contentful]
  );
  // console.log(menuData)

  const mobileClick = (e, subNavId, back) => {
    if (back) {
      e.stopPropagation();
      const currentSubNav = e.currentTarget.closest('[data-subnav]');
      currentSubNav.classList.toggle(styles.isActive);
    } else {
      const subNav = e.currentTarget.querySelectorAll(
        `[data-subnav="${subNavId}"]`
      );
      if (subNav.length > 0) {
        e.stopPropagation();
        subNav[0].classList.toggle(styles.isActive);
      }
    }
  };

  const linkTo = (e, link) => {
    e.preventDefault();
    /* Force page to reload to link in the event a link with only filter changes is clicked */
    if (typeof window !== 'undefined') {
      window.location = `/${link !== '/' ? link : ''}`;
    }
  };

  const toggleActiveNav = useCallback(
    (e, itemIndex, level, childrenItems) => {
      if (level === 1 && childrenItems) {
        setNavOpen(true);
      }
      if (level === 2) {
        setActiveNav(itemIndex);
      }
      // console.log('called');
    },
    [setNavOpen]
  );

  const closeActiveNav = (level, childrenItems) => {
    if (level === 1 && childrenItems) {
      setNavOpen(false);
    }
  };

  const isActiveNav = (itemIndex, level) => {
    if (level === 2) {
      if (activeNav === itemIndex) return true;
    }

    return false;
  };

  const menuLevel = (menuList, parentLabel, className, level, parentIndex) => {
    const items = menuList?.map((item, itemIndex) => {
      if (item.menuLabel === 'ImageGridStyle' && level === 3) {
        if (item.submenu && item.submenu.length > 0) {
          return (
            <li key={itemIndex} className={styles.imageGrid}>
              {item.submenu?.map((image, imageIndex) => {
                return (
                  <div key={imageIndex}>
                    <a
                      href={image.menuLink}
                      onClick={e => linkTo(e, image.menuLink)}>
                      <img
                        loading='lazy'
                        src={image.image}
                        alt={image.menuLabel}
                      />
                      <span>{image.menuLabel}</span>
                    </a>
                  </div>
                );
              })}
            </li>
          );
        } else {
          return null;
        }
      } else if (level === 2 && !item.submenu) {
        return (
          <li key={itemIndex}>
            <Link to={item.url} onClick={e => linkTo(e, item.url)}>
              {item.title}
            </Link>
          </li>
        );
      } else {
        let childrenItems = null;
        if (item.submenu && item.submenu.length > 0) {
          childrenItems = menuLevel(
            item.submenu,
            item.menuLabel,
            styles.subnav,
            level + 1,
            itemIndex
          );
        }

        return (
          <li
            key={itemIndex}
            className={`${isActiveNav(itemIndex, level) ? styles.active : ''} ${
              childrenItems ? styles.withChildren : ''
            }`}
            onMouseEnter={e => {
              toggleActiveNav(e, itemIndex, level, childrenItems);
              getPositionX(itemIndex);
            }}
            onMouseLeave={
              !isTabletView
                ? () => closeActiveNav(level, childrenItems)
                : undefined
            }
            onClick={e => mobileClick(e, `${itemIndex}-${level + 1}`)}
            role='presentation'
            onTouchStart={() => getPositionX(itemIndex)}
            ref={ref => boxRef.current.push(ref)}>
            {item.menuLink && item.menuLink !== '' && !item.target && (
              <Link to={item.menuLink} onClick={e => linkTo(e, item.menuLink)}>
                {item.menuLabel}
              </Link>
            )}
            {item.menuLink && item.menuLink !== '' && item.target && (
              <a
                href={item.menuLink}
                title={item.menuLabel}
                onClick={e => linkTo(e, item.menuLink)}>
                {item.menuLabel}
              </a>
            )}
            {(!item.menuLink || item.menuLink === '') && (
              <span
                className={`${styles.fauxLink} ${
                  item.menuLabel === parentLabel ? styles.duplicate : ''
                }`}>
                {item.menuLabel}
              </span>
            )}
            {childrenItems && childrenItems}
          </li>
        );
      }
    });
    // console.log(items);
    return (
      <>
        {level === 2 && (
          <div
            className={`${className} ${styles[`level${level}`]}`}
            data-subnav={`${parentIndex}-${level}`}>
            <span
              role='presentation'
              className={styles.backLink}
              onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}>
              Back
            </span>
            {parentLabel && (
              <span className={styles.parentLabel}>{parentLabel}</span>
            )}
            <div className={styles.shadow}>
              <Container size='large'>
                <ul style={isOffset ? { marginLeft: isOffset + 'px' } : {}}>
                  {!items ? null : items}
                </ul>
              </Container>
            </div>
          </div>
        )}
        {level !== 2 && (
          <div
            className={`${className} ${styles[`level${level}`]}`}
            data-subnav={`${parentIndex}-${level}`}>
            <span
              role='presentation'
              className={styles.backLink}
              onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}>
              Back
            </span>
            {parentLabel && (
              <span className={styles.parentLabel}>{parentLabel}</span>
            )}
            <ul>{items}</ul>
          </div>
        )}
      </>
    );
  };
  useEffect(() => {
    if (activeMenu.length === 0) {
      setActiveMenu(menuData);
    }
  }, [menuData]);

  return menuLevel(activeMenu, null, styles.root, 1, 0);
};

export default SiteMenu;

