import React from 'react';

import LogoSVG from './asset';
// import LogoSVGWhite from './asset-white';
// import LogoSVGWhiteMobile from './asset-mobile-white';
import LogoSVGMobile from './asset-mobile';
// import LogoPNG from './asset.png'

import * as styles from './Logo.module.css';

const Logo = ({siteTitle, headerStyle, isScrolled}) => {
    return (
        <div className={`${styles.root} ${headerStyle === 'dark' ? styles.homepageStyle : styles.defaultLogo} ${headerStyle === 'dark' && isScrolled ? styles.homepageScrolled : ''}`}>
            <LogoSVG siteTitle={siteTitle} fill="#7B2CBF" />
            <LogoSVGMobile siteTitle={siteTitle} fill="#7B2CBF" />
        </div>
    );
};

export default Logo