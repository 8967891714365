import React from 'react';

const ArrowWithTail = () => (

  <svg viewBox="0 0 14 14" width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7.5H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 13L13 7.00473L7 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ArrowWithTail;
