import React from "react"
import Slick from "react-slick";

import './slick.css'
import './slick-theme.css'

const Slider = props => {
    const { children, sliderRef } = props;
    const defaultSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        touchThreshold: 5,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const settings = { ...defaultSettings, ...props }
    
    return (
        <Slick ref={sliderRef !== undefined ? sliderRef : undefined} {...settings}>
            { children }
        </Slick>
    )
}

export default Slider