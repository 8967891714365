import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useState, useCallback, useEffect } from "react"
import AuthContext from '../../../context/AuthProvider';
import Container from "../../atoms/Container/Container";
// import InformationBar from "../../molecules/InformationBar/InformationBar";
import Logo from "../../atoms/Logo/Logo";
import Icon from "../../atoms/Icon/Icon";
import SiteMenu from '../../molecules/SiteMenu/SiteMenu'
import UserMenu from '../../molecules/UserMenu/UserMenu'
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
import Search from '../../molecules/Search/Search'

import * as styles from './Header.module.css';

const Header = ({ siteTitle, messages, headerstyle }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [searchOverflow, setSearchOverflow] = useState(false)
  const [showScrolled, setShowScrolled] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isActiveAccountMenu, toggleAccountMenu] = useState(false);

  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const firstName = auth && auth.state.object && auth.state.object.first_name;

  // TODO: Obtain menu structure from external system to replace this default. Must match the structure example in /molecules/SiteMenu
  const handleScroll = useCallback(() => {
    if (window.scrollY > 30 && !showScrolled) {
        setShowScrolled(true);
    } else if (window.scrollY <= 30 && showScrolled) {
        setShowScrolled(false);
    }
  }, [showScrolled, setShowScrolled]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
        document.removeEventListener('scroll', handleScroll);
    };
  }, [showScrolled, handleScroll]);

  const hideSearch = () => {
    setSearchOverflow(false)
    setShowSearch(!showSearch);
  }

  const allowSearchOverflow = (toggle) => {
    setSearchOverflow(toggle)
  }

  return (
    <section className={`${styles.headerWrap} ${headerstyle === 'dark' ? styles.headerHome : ''} ${showScrolled ? styles.headerScrolled : ''}`} data-header={true} data-page-stayle={headerstyle}>
      <div className={`${styles.shroud} ${isNavOpen ? styles.isActive : ''}`} onClick={() => setNavOpen(!isNavOpen)} role="presentation"></div>
      {/* <InformationBar messages={messages} /> */}
      <header className={styles.header}>
        <Container size="large">
          {/* Mobile menu */}
          <div className={styles.burgerMenu}>
            <div className={`${styles.menuIcon}`} role="presentation" onClick={() => setNavOpen(!isNavOpen)}> 
              <Icon symbol="hamburger" />
            </div>
          </div>

          {/* Search */}
          <div className={`${styles.searchWrapper} ${showSearch ? styles.active : ''} ${searchOverflow ? styles.overflow : ''}`}>
            <div className={styles.search}>
              <span className={styles.close} role="presentation" onClick={() => hideSearch()}><Icon symbol="close" /></span>
              <Search toggleSuggestions={allowSearchOverflow} showing={showSearch} />
            </div>
          </div>

          {/* Site menu */}
          <div className={`${styles.siteMenu} ${isNavOpen ? styles.isActive : ''}`}>
            <div className={`${styles.mobileLogo}`}>
              <Link to="/"><Logo siteTitle={siteTitle} /></Link>
            </div>
            <span className={`${styles.mobileClose}`} onClick={() => setNavOpen(!isNavOpen)} role="presentation"><Icon symbol="close" /></span>

            <SiteMenu setNavOpen={setNavOpen} headerstyle={headerstyle}/>
            
            <div className={styles.mobileAccount}>
              {!isLoggedIn && (
                <>
                  <div className={`flex-middle ${styles.mobileLogins}`}>
                  <Icon symbol="userblack" />
                  <Link to="/sign-in">Sign in</Link>
                  /
                  <Link to="/create-account">Create Account</Link>
                  </div>
                </>
              )}
              {isLoggedIn && (
                <div data-accountmenuwrap>
                  <span role="presentation" className={styles.fauxLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}><Icon symbol="user" /> Hi {firstName}</span>
                  <div className={`${isActiveAccountMenu ? styles.isActive : ''}`} data-accountmenu>
                    <span role="presentation" className={styles.backLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}>Back</span>
                    <span className={styles.parentLabel}>My Account</span>
                    <div className={styles.accountItems}>
                      <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} />
                    </div>
                  </div>
                </div>
              )}
              <div className={`flex-middle ${styles.mobileLogins}`}>
                <Icon symbol="faqs" />
                <Link to="/faqs">FAQ</Link>
              </div>
            </div>
          </div>

          {/* Logo */}
          <div className={`${styles.title} ${headerstyle} ${showScrolled}`}>
            <Link to="/"><Logo siteTitle={siteTitle} headerStyle={headerstyle} isScrolled={showScrolled}/></Link>
          </div>

          {/* User menu */}
          <div className={styles.userMenu}>
            <UserMenu headerstyle={headerstyle} isScrolled={showScrolled} searchVisibility={showSearch} searchHandler={setShowSearch} isLoggedIn={isLoggedIn} firstName={firstName} />
          </div>

        </Container>
      </header>
    </section>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
