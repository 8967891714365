import React from 'react'

const User = ({ fill }) => (
    <svg
        width="23"
        height="25"
        viewBox="0 0 23 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4022 0C7.37523 0 4.11084 3.40502 4.11084 7.60515C4.11084 10.2685 5.42344 12.6122 7.41078 13.9706C3.11131 15.5484 0 19.5438 0 24.3258H1.95756C1.95756 19.4168 6.14782 15.2891 11.4999 15.2891C16.8521 15.2891 21.0424 19.4168 21.0424 24.3258H23C23 19.4963 19.8264 15.469 15.4606 13.9243C17.4104 12.5593 18.6936 10.2385 18.6936 7.60515C18.6936 3.40502 15.4292 0 11.4022 0ZM6.0684 7.60515C6.0684 4.53244 8.45651 2.04165 11.4022 2.04165C14.3479 2.04165 16.7361 4.53244 16.7361 7.60515C16.7361 10.6779 14.3479 13.1687 11.4022 13.1687C8.45651 13.1687 6.0684 10.6779 6.0684 7.60515Z"
            fill={fill || '#290F40'}
        />
    </svg>

)

export default User