import React from "react"
import Icon from '../../atoms/Icon/Icon'
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
// import NavWishlist from '../../molecules/NavWishlist/NavWishlist'
// import NavCart from '../../molecules/NavCart/NavCart'
// import CurrencySelect from '../../molecules/CurrencySelect/CurrencySelect'

import * as styles from './UserMenu.module.css'

const UserMenu = ({headerstyle, isScrolled, searchVisibility, searchHandler, isLoggedIn, firstName}) => {
    return (
        <>
            {/* <div className={styles.userMenuItem} role="presentation">
                <CurrencySelect styles={styles} />
            </div>
            <div className={styles.userMenuItem} role="presentation">
                <Icon symbol="translate" />
            </div>
            <div className={`${styles.userMenuItem}`} role="presentation" onClick={() => searchHandler(!searchVisibility)}>
                <Icon symbol="search" />
            </div>
            <div className={`${styles.userMenuItem}`}>
                <NavWishlist styles={styles} />
            </div> */}
            <div className={`${styles.userMenuItem} ${isLoggedIn ? styles.loggedIn : null}  ${headerstyle === 'dark' ? styles.homepageStyle : styles.defaultUser} ${headerstyle === 'dark' && isScrolled ? styles.homepageScrolled : ''}`}>
                <Icon symbol="user" />
                <div className={styles.userDropdown}>
                    <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} />
                </div>
            </div>
            {/* <div className={`${styles.userMenuItem} ${headerstyle === 'dark' ? styles.homepageStyleCart : styles.defaultCart } ${headerstyle === 'dark' && isScrolled ? styles.homepageScrolled : ''}`}>
                <NavCart styles={styles} mode="drawer" />
            </div> */}
        </>
    )
}

export default UserMenu