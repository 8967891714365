// extracted by mini-css-extract-plugin
export var modal = "PayPalInfo-module--modal--cInn1";
export var header = "PayPalInfo-module--header--Hh8jF";
export var intro = "PayPalInfo-module--intro--Q5X60";
export var svgSection = "PayPalInfo-module--svgSection--QSbbt";
export var titles = "PayPalInfo-module--titles--KbiwJ";
export var body = "PayPalInfo-module--body--MzydU";
export var block = "PayPalInfo-module--block--ywIfH";
export var imgContainer = "PayPalInfo-module--imgContainer--Dc07+";
export var blockSubtitle = "PayPalInfo-module--blockSubtitle--Y-Vgs";
export var blockMessage = "PayPalInfo-module--blockMessage--9jgNQ";
export var footer = "PayPalInfo-module--footer--Ns9Wg";
export var bottomLine = "PayPalInfo-module--bottomLine--thPiK";
export var zipPay = "PayPalInfo-module--zipPay--71Mos";
export var zipMoney = "PayPalInfo-module--zipMoney--AgE92";