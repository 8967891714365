import React from 'react'

import * as styles from './ProductFlags.module.css';

const ProductFlags = ({ data, inventory }) => {
    let productHasCustomFlag = false;
    if (data.customFlags) {
        productHasCustomFlag = data.customFlags; // Needs to be modified to work with the data for whereever custom flags are setup
    }

    return (
        <div className={styles.flagContainer}>
            {data && data.sale_price > 0 && (
                <span className={`${styles.flag} ${styles.orange}`}>ON SALE</span>
            )}
            {inventory && typeof inventory.stockLevel === 'number' && inventory.stockLevel > 0 && inventory.stockLevel < 5 && (
                <span className={`${styles.flag} ${styles.yellow}`}>LOW STOCK</span>
            )}
            {inventory && inventory.availability === 'Preorder' && (
                <span className={`${styles.flag}`}>PRE ORDER</span>
            )}
            {productHasCustomFlag && productHasCustomFlag.map((flag, flagIndex) => (
                <span key={`customFlag-${flagIndex}`} className={`${styles.flag}`} title={flag.description}>{flag.label}</span>
            ))}
            {/* TODO: FLAG FOR NEW PRODUCT MISSING CONDITION
            {productIsNew && (
                <span className={`${styles.flag} ${styles.maroon}`}>NEW</span>
            )} */}
        </div>
    )
}

export default ProductFlags;