// extracted by mini-css-extract-plugin
export var brandLogoContainer = "ProductDetails-module--brandLogoContainer--InnAZ";
export var titleContainer = "ProductDetails-module--titleContainer--Rmnr9";
export var wishIconContainer = "ProductDetails-module--wishIconContainer--2HvBW";
export var wishIcon = "ProductDetails-module--wishIcon--EOH7A";
export var skuContainer = "ProductDetails-module--skuContainer--QE9L8";
export var prices = "ProductDetails-module--prices--T0U1u";
export var price = "ProductDetails-module--price--8MI-D";
export var salePrice = "ProductDetails-module--salePrice--x4uNU";
export var savings = "ProductDetails-module--savings--NxvEW";
export var reviewInventoryCheck = "ProductDetails-module--reviewInventoryCheck--Cfmlf";
export var reviewContainer = "ProductDetails-module--reviewContainer--2nKiD";
export var inventoryCheckContainer = "ProductDetails-module--inventoryCheckContainer--V8Caa";
export var rating = "ProductDetails-module--rating--1WN8X";
export var paymentOptions = "ProductDetails-module--paymentOptions--7dTiv";
export var poContainer = "ProductDetails-module--poContainer--3a-rn";
export var klarnaIconContainer = "ProductDetails-module--klarnaIconContainer--nfC+7";
export var afterpayIconContainer = "ProductDetails-module--afterpayIconContainer--ahVNg";
export var paypalColorIconContainer = "ProductDetails-module--paypalColorIconContainer--cawUZ";
export var zipLogoContainer = "ProductDetails-module--zipLogoContainer--zuWli";
export var warrantyContainer = "ProductDetails-module--warrantyContainer--VYHvQ";
export var warrantyTitle = "ProductDetails-module--warrantyTitle---LqBr";
export var optionContainer = "ProductDetails-module--optionContainer--9Z1Hz";
export var checkoutContent = "ProductDetails-module--checkoutContent--8m19h";
export var qtyContainer = "ProductDetails-module--qtyContainer--TPLCw";
export var checkoutContainer = "ProductDetails-module--checkoutContainer--uGJ4c";
export var fastCheckoutButtonContainer = "ProductDetails-module--fastCheckoutButtonContainer--uC6u7";
export var shippingOptionsContainer = "ProductDetails-module--shippingOptionsContainer--BnNpL";
export var shippingOption = "ProductDetails-module--shippingOption--xIZyW";
export var shippingBorder = "ProductDetails-module--shippingBorder--pQDj3";
export var postcodeContainer = "ProductDetails-module--postcodeContainer--0e6sz";
export var truckIconContainer = "ProductDetails-module--truckIconContainer--SCJBp";
export var purseIconContainer = "ProductDetails-module--purseIconContainer--7aXte";
export var clickIconContainer = "ProductDetails-module--clickIconContainer--3gcS9";
export var shippingTitleContianer = "ProductDetails-module--shippingTitleContianer--rphQa";
export var collectIconsContainer = "ProductDetails-module--collectIconsContainer--tGsjJ";
export var collectContainer = "ProductDetails-module--collectContainer--6zDmQ";
export var storeAvailability = "ProductDetails-module--storeAvailability--HV+OZ";
export var storeDetail = "ProductDetails-module--storeDetail--osJxq";
export var storeTitle = "ProductDetails-module--storeTitle--hoMRf";
export var storeName = "ProductDetails-module--storeName--Jy6VV";
export var storeAddress = "ProductDetails-module--storeAddress--X-le1";
export var mobileShippingContainer = "ProductDetails-module--mobileShippingContainer--oZ99y";
export var goButton = "ProductDetails-module--goButton--qfJQV";
export var variantOptionsContainer = "ProductDetails-module--variantOptionsContainer--x5ZxF";
export var optionsTitle = "ProductDetails-module--optionsTitle--uMIuj";
export var variantOptionsWrap = "ProductDetails-module--variantOptionsWrap--HqEXf";
export var variantOptions = "ProductDetails-module--variantOptions--q2eHY";
export var option = "ProductDetails-module--option--p8i6c";
export var active = "ProductDetails-module--active--diRwT";
export var variantSwatches = "ProductDetails-module--variantSwatches--a8eQ2";
export var swatch = "ProductDetails-module--swatch--u-NxG";
export var addToCartContainer = "ProductDetails-module--addToCartContainer--YVMyr";
export var addToCartHeader = "ProductDetails-module--addToCartHeader--KPUJJ";
export var maxQtyMessage = "ProductDetails-module--maxQtyMessage--ctn-F";
export var stickyAddToCart = "ProductDetails-module--stickyAddToCart--AP1Wj";
export var stickyAddToCartButtonContainer = "ProductDetails-module--stickyAddToCartButtonContainer--46HrH";
export var stickAddToCartContent = "ProductDetails-module--stickAddToCartContent--hPrg8";
export var border = "ProductDetails-module--border--hqHd2";
export var stickyAddToCartLeft = "ProductDetails-module--stickyAddToCartLeft--mb8JS";
export var stickyAddToCartRight = "ProductDetails-module--stickyAddToCartRight--ROrDO";
export var stickyAddToCartImageContainer = "ProductDetails-module--stickyAddToCartImageContainer--BhRkO";
export var stickAddToCartDetails = "ProductDetails-module--stickAddToCartDetails--vEzwT";
export var onSaleTag = "ProductDetails-module--onSaleTag--Hm-7l";
export var productTitleSticky = "ProductDetails-module--productTitleSticky--wIMIB";
export var show = "ProductDetails-module--show--Lxjbi";
export var zipIconContainer = "ProductDetails-module--zipIconContainer--2np5C";
export var tabStickyContainer = "ProductDetails-module--tabStickyContainer--yn2SM";
export var stickyPaymentOptionContainer = "ProductDetails-module--stickyPaymentOptionContainer--DN5lC";
export var stickyTabContainer = "ProductDetails-module--stickyTabContainer--e8oRf";
export var activeStickyTabContainer = "ProductDetails-module--activeStickyTabContainer--OutIi";
export var stickyFooter = "ProductDetails-module--stickyFooter--Uo3m6";
export var stickyFooterButtonContainer = "ProductDetails-module--stickyFooterButtonContainer--SjKbE";
export var stickyFooterActive = "ProductDetails-module--stickyFooterActive--3aJWM";
export var descriptionContainer = "ProductDetails-module--descriptionContainer--nk5ub";
export var postCode = "ProductDetails-module--postCode--qwkTI";
export var mobileFulfillmentContainer = "ProductDetails-module--mobileFulfillmentContainer--6OAq0";
export var mobileProductImageContainer = "ProductDetails-module--mobileProductImageContainer--PuqPZ";
export var videoContainer = "ProductDetails-module--videoContainer--QNHvj";
export var productDetails = "ProductDetails-module--productDetails--FA5ew";
export var designName = "ProductDetails-module--designName--xddFe";
export var rateWrap = "ProductDetails-module--rateWrap--lbHgI";
export var hidePricing = "ProductDetails-module--hidePricing--OtkjV";
export var productPrice = "ProductDetails-module--productPrice--ItAWK";
export var priceDetails = "ProductDetails-module--priceDetails--dt+3m";
export var warranty = "ProductDetails-module--warranty--wJP0e";
export var productQtyFav = "ProductDetails-module--productQtyFav--3aWc3";
export var qtyMinus = "ProductDetails-module--qtyMinus--zop13";
export var qtyPlus = "ProductDetails-module--qtyPlus--a1t1b";
export var addToCartButton = "ProductDetails-module--addToCartButton--Tc165";
export var preorderDetail = "ProductDetails-module--preorderDetail--u528d";
export var productMessage = "ProductDetails-module--productMessage--tFY+C";
export var productDescription = "ProductDetails-module--productDescription--eC07w";
export var completeTheLook = "ProductDetails-module--completeTheLook--BxWho";
export var noReviews = "ProductDetails-module--noReviews--sVpPL";