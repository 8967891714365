import React, { useEffect, useState } from 'react';
import { has } from 'lodash';
import { scroller } from 'react-scroll';
import Slider from '../../atoms/Slider/Slider';
import { useWindowDimensions } from '../../../helpers/general';
import InnerImageZoom from 'react-inner-image-zoom';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import * as styles from './ProductImages.module.css'

const ProductImages = props => {
    const { images, identifier, type, imageStyling, igRef, output = 'slider' } = props;
    const { width } = useWindowDimensions();

    const [sortedImages, setSortedImages] = useState([]);

    useEffect(() => {
        if (images && sortedImages.length === 0 && images.length > 0) {
            const imagesObj = [...images];
            imagesObj.sort(a => a.is_thumbnail ? -1 : 1);
            setSortedImages(imagesObj);
        }
    }, [images, sortedImages]);

    const customPaging = () => {
      return(<div className={styles.mobilePagination}></div>)
    }

    const onClickThumbScroller = evt => {
        evt.preventDefault();
        if (window.innerWidth > 767) {
          scroller.scrollTo(
            evt.currentTarget.getAttribute('href').replace('#', ''),
            {
              duration: 800,
              delay: 0,
              offset: -180,
              smooth: 'easeInOutQuart'
            }
          );
        } else {
          const _parent = document.querySelector(
            `.${evt.currentTarget.getAttribute('data-image-class')}`
          ).parentElement;
          document
            .querySelectorAll(
              `.${evt.currentTarget.getAttribute('data-image-class')}`
            )
            .forEach((_el, _index) => {
              _el.classList.remove(
                evt.currentTarget.getAttribute('data-image-class')
              );
            });
    
          _parent
            // .querySelectorAll('img')
            .querySelectorAll('.productImageContainer')
            [
              parseInt(evt.currentTarget.getAttribute('data-key'), 10)
            ].classList.add(evt.currentTarget.getAttribute('data-image-class'));
        }
    
        return false;
    };

    const onClickThumbSlider = (index) => {
      if (igRef?.current !== undefined) {
        igRef.current.slickGoTo(index);
      }
    }

    const sliderSettings = {
      infinite: false,
      slidesToShow: 1,
      arrows: false,
      speed: 150,
      fade: true,
      dotsClass: `${styles.customDots}`,
      swipe: width > 799 ? false : true,
      dots: width > 799 ? false : true,
      customPaging: customPaging,
    }

    const thumbnailOptions = index => {
      if (output === 'scroller') {
        return {
          onClick: onClickThumbScroller,
          href: `#${identifier}-${index + 1}`
        }
      }

      if (output === 'slider') {
        return {
          onClick: () => onClickThumbSlider(index)
        }
      }

      return null;
    }

    return (
        <>
            {(type === 'thumbnails' && sortedImages && sortedImages.length > 1) && sortedImages.map((img, index) => (
                <a
                    {...thumbnailOptions(index)}
                    role="presentation"
                    key={index}
                    data-key={index}
                    data-image-class={styles.productImageActive}
                    className={styles.productImageThumb}
                >
                    <img
                        loading="lazy"
                        src={
                            has(img, 'node') ? img.node.url : img.url_thumbnail
                        }
                        alt={img.description}
                    />
                </a>
            ))}

            {(type === 'gallery' && output === 'scroller' && sortedImages) && sortedImages.map((img, index) => (
                <div id={`${identifier}-${index + 1}`} key={index} className={`productImageContainer ${index === 0 ? styles.productImageActive : ''}`}>
                  <InnerImageZoom 
                    src={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    zoomSrc={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    alt={img.description}
                  />
                </div>
            ))}
            {(type === 'gallery' && output === 'slider' && sortedImages) && 
              <Slider {...sliderSettings} sliderRef={igRef}>
                {sortedImages.map((img, index) => {
                  const isMobile = width > 799 ? false : true;
                  if (isMobile) {
                    return (<div key={index}>
                      <img alt={img.description} className={styles.mobileImage} src={
                          has(img, 'node')
                            ? img.node.url
                            : img.url_standard
                        }></img>
                    </div>);
                  } else {
                    return(
                      <div
                      id={`${identifier}-${index + 1}`} 
                      key={index} 
                      className={`productImageContainer ${index === 0 ? styles.productImageActive : ''}`}>
                      <InnerImageZoom
                        className={imageStyling !== undefined ? imageStyling : ''}
                        width={580}
                        height={580}
                        hasSpacer
                        src={
                          has(img, 'node')
                            ? img.node.url
                            : has(img, 'url_zoom')
                            ? img.url_zoom
                            : img.url_standard
                        }
                        zoomSrc={
                          has(img, 'node')
                            ? img.node.url
                            : has(img, 'url_zoom')
                            ? img.url_zoom
                            : img.url_standard
                        }
                        alt={img.description}
                      />
                    </div>
                    )
                  }
                })}
              </Slider>
            }
            {(type === 'gallery' && (!sortedImages || sortedImages.length === 0)) && (
                <div>
                    <img
                        loading="lazy"
                        src="https://via.placeholder.com?1000x1000?text=No+Images+Available"
                        alt="No product images"
                    />
                </div>
            )}
        </>
    )
}

export default ProductImages;